<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>

        <!-- ********************* OVERVIEW *************************** -->
        <section id="overview">
          <div class="bsat__paragraph">{{ $t("overview.paragraph") }}</div>
          <ReadMore>
            <div class="bsat__paragraph">{{ $t("overview.paragraph2") }}</div>
            <div class="bsat__paragraph">{{ $t("overview.paragraph3") }}</div>
          </ReadMore>
        </section>

        <!-- ***************************** SUMMARY ***************************** -->
        <!-- <div class="steps__subtitle bsat__subtitle mb-4">
          {{ $t("summary.title") }}
        </div>
        <div
          v-for="(item, i) in summaryUnorderedList"
          :key="i"
          class="bsat__paragraph"
          v-text="item"
        ></div> -->

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>
          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.taskLeader.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </ReadMore>
        </section>

        <!-- ***************************** RECOMMENDED RESOURCES ***************************** -->

        <section id="recommendedResources">
          <div class="bsat__subtitle mb-4">
            {{ $t("recommendedResources") }}
          </div>

          <!-- <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResourcesIntro") }}
          </div> -->

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <!-- ******************************* TOOLS REPOSITORY **************************** -->
              <div>
                <i18n
                  path="toolsRepository.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link>
                    <strong>
                      {{ $t("toolsRepository.link") }}
                    </strong>
                  </template>
                </i18n>
              </div>

              <ul>
                <li
                  class="bsat__paragraph mb-3"
                  v-for="(item, i) in regionalPolicies"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>

              <v-btn
                outlined
                width="150"
                height="26"
                class="bsat__btn"
                color="dark"
                :to="toolsRepositoryLink"
                >{{ $t("visit") }}</v-btn
              >
              <!-- <a
                :href="require('@/assets/files/financial_tools.docx').default"
                target="_blank"
                download
              >
                <v-btn
                  class="bsat__btn"
                  color="dark"
                  dark
                  depressed
                  width="150"
                  height="26"
                  >{{ $t("download") }}</v-btn
                >
              </a> -->
            </li>

            <li>
              <!-- ***************************** REGIONAL POLICIES ***************************** -->
              <div>
                <i18n
                  path="regionalPolicies.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link>
                    <strong>{{ $t("regionalPolicies.link") }}</strong>
                  </template>
                </i18n>
              </div>

              <!-- <ul>
                <li
                  class="bsat__paragraph mb-3"
                  v-for="(item, i) in regionalPolicies"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul> -->

              <v-btn
                outlined
                width="150"
                height="26"
                class="bsat__btn"
                color="dark"
                :to="regionalPoliciesLink"
                >{{ $t("visit") }}</v-btn
              >

              <!-- <a
                :href="require('@/assets/files/financial_tools.docx').default"
                target="_blank"
                download
              >
                <v-btn
                  class="bsat__btn"
                  color="dark"
                  dark
                  depressed
                  width="150"
                  height="26"
                  >{{ $t("download") }}</v-btn
                >
              </a> -->
            </li>

            <li>
              <!-- ******************* RESULTS ************************ -->
              <div>
                <i18n path="results.text" tag="div" class="bsat__paragraph">
                  <template #link1>
                    <a @click="goToTab(1)">{{ $t("results.link1") }}</a>
                  </template>
                  <template #link2>
                    <a @click="goToTab(2)">{{ $t("results.link2") }}</a>
                  </template>
                </i18n>
              </div>
            </li>

            <li>
              <!-- ***************** ANALYSIS ***************** -->
              <div class="bsat__paragraph">{{ $t("analysis") }}</div>
            </li>

            <li>
              <!-- ****************** RESULTS2 ****************** -->
              <div>
                <i18n path="results2.text" tag="div" class="bsat__paragraph">
                  <template #link1>
                    <a @click="goToStep(1)">{{ $t("results2.link1") }}</a>
                  </template>
                  <template #link2>
                    <a @click="goToStep(2)">{{ $t("results2.link2") }}</a>
                  </template>
                  <template #link3>
                    <a @click="goToStep(3)">{{ $t("results2.link3") }}</a>
                  </template>
                  <template #link4>
                    <a @click="goToStep(4)">{{ $t("results2.link4") }}</a>
                  </template>
                </i18n>
              </div>
            </li>
            <li>
              <div class="bsat__paragraph">{{ $t("questionnaire") }}</div>
              <!-- TODO: 1.3.5 questioannaire -->
              <a :href="questionnaireFile" target="_blank" download>
                <v-btn
                  depressed
                  width="150"
                  height="26"
                  class="bsat__btn"
                  dark
                  color="dark"
                  >{{ $t("download") }}</v-btn
                >
              </a>
            </li>
          </ul>
        </section>
      </div>

      <i18n
        class="mt-8"
        tag="div"
        path="downloadAll.text"
        style="font-family: Montserrat"
      >
        <template #link>
          <a class="dark-text mt-8" :href="zip" target="_blank" download>
            {{ $t("downloadAll.link") }}
          </a>
        </template>
      </i18n>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "analysis": "Analysis and conclusion of the whole participatory approach. ",
    "implementationRecommendations": {
      "proposedMethods": {
        "title": "Proposed methods",
        "unorderedList": [
          "Feedback rounds (as many as necessary) with decision-level of Interministerial/interdepartmental group and advisory group.  ",
          "Analysis of collected information during strategy development process."
        ]
      },
      "task": {
        "text": "{title}: Draft the regional bioeconomy strategy document",
        "title": "Task"
      },
      "taskLeader": {
        "text": "{title}: Drafting group: designated technical expert from involved ministries or governmental departments",
        "title": "Task leader"
      },
      "title": "Implementation Recommendations"
    },
    "overview": {
      "paragraph": "The regional bioeconomy strategy will be drafted by the designated group (as explained in Step 1) and supported in so many instances as seem necessary by the decision level of the Interministerial group.",
      "paragraph2": "Structural aspects of the strategy development are relevant, such as the definition of the participatory process approach, the choice of topics discussed with engaged stakeholders, their contributions and main conclusions from their feedback. Finally, the strategy draft should also include the determined priority areas, and the regional bioeconomy vision.",
      "paragraph3": "Some regions might decide after this step to finalise the strategy draft and carry out a final consultation process of the strategy document. Also, in other cases, some regions might consider, the development of two different documents, the strategy document and the roadmap document. The former strategy can be used as the framework document for the long-term, and the latter one as a separate and complementary document to operationalise the strategy in the short to medium term. In any case, either if the roadmap is embedded in the strategy itself or it is created as a separate document, it is suggested to continue with the roadmap definition (Phase 4) before finalizing the strategy development process."
    },
    "recommendedResources": "Recommended resources:",
    "regionalPolicies": {
      "link": "Repository of supporting documents and tools for regional policies in the field of bioeconomy:",
      "text": "{link} As for the regulatory and incentives area, it is also pertinent at this stage to refer to general experiences and conclusions from previous cases studied in the POWER4BIO project.",
      "unorderedList": [
        "General barriers and opportunities for policies in the bioeconomy field ",
        "Recommendations for the development of tailor-made policy instruments to boost bioeconomy"
      ]
    },
    "results": {
      "link1": "Phase 1",
      "link2": "Phase 2",
      "text": "Results from {link1} and {link2}"
    },
    "results2": {
      "link1": "Step 1",
      "link2": "2",
      "link3": "3",
      "link4": "4",
      "text": "Results from {link1}, {link2}, {link3} and {link4} of strategy development (Current Phase 3)"
    },
    "questionnaire": "Questionnaire to support the Bioeconomy strategy development at a very advanced stage of the process",
    "title": "Step 5: Draft the strategy document",
    "toolsRepository": {
      "link": "Repository of supporting documents and tools for the implementation of EU financial instruments in the area of bioeconomy:",
      "text": "{link} At this stage, it is instrumental to review the work accomplished so far with general remarks to complement and “help you think” against your own developments. Specially consult resources 6 and 7.",
      "unorderedList": [
        "Synergies between different funding programmes",
        "Brief list of Recommendations to create financial schemes for bioeconomy"
      ]
    },
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import ReadMore from "@/components/ReadMore";
import { EventBus } from "@/utils/EventBus";
export default {
  name: "Phase3Step5",
  components: {
    ReadMore
  },
  data() {
    return {
      toolsRepositoryLink: "/repository-of-financing-instruments-and-tools",
      regionalPoliciesLink:
        "/repository-of-regional-policies-to-support-bio-based-models",
      questionnaireFile: require("@/assets/files/1_3_5_Questionnaire.docx")
        .default,
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    },
    toolsRepository() {
      return Object.values(this.$t("toolsRepository.unorderedList"));
    },
    regionalPolicies() {
      return Object.values(this.$t("regionalPolicies.unorderedList"));
    },
    summaryUnorderedList() {
      return Object.values(this.$t("summary.unorderedList"));
    },
    resourcesUnorderedList() {
      return Object.values(this.$t("recomendedResources.unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    },
    goToStep(step) {
      EventBus.$emit("goToStep", step);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
