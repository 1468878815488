import { render, staticRenderFns } from "./Step5.vue?vue&type=template&id=06400cfc&scoped=true&"
import script from "./Step5.vue?vue&type=script&lang=js&"
export * from "./Step5.vue?vue&type=script&lang=js&"
import style0 from "./Step5.vue?vue&type=style&index=0&id=06400cfc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06400cfc",
  null
  
)

/* custom blocks */
import block0 from "./Step5.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCard,VCardText})
